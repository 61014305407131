import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import { Link as RLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { Container, Stack } from "@mui/material";
import { GetApp } from "@mui/icons-material";
import { Context } from "../context/context";
import Hero from "./Hero";
import AppAppBar from "./AppAppBar";
import TemplateSkeleton from "./TemplateSkeleton";

export default function Templates() {
  const { country, currency, templates } = React.useContext(Context);
  const [delay, setDelay] = React.useState(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setDelay(false);
    }, 2000);
  }, []);

  return (
    <>
      <AppAppBar temp="1" />
      {templates.length === 0 || delay ? (
        <TemplateSkeleton />
      ) : (
        <>
          <Hero temp="1" />
          <Container>
            <Grid container spacing={2} sx={{ pb: 2 }}>
              {templates.map((layout, index) => {
                console.log(layout.title);
                return (
                  <Grid size={{ xs: 12, sm: 6 }} key={layout.title}>
                    <Card
                      variant="outlined"
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        borderColor: "divider",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          "&:hover > .MuiCardMedia-root": {
                            filter: "blur(4px)",
                          },
                          "&:hover > .MuiButtonBase-root": {
                            opacity: 1,
                          },
                        }}
                      >
                        <CardMedia
                          component="img"
                          // The image source is generated from `pnpm template:screenshot material-ui`, do not modify the image manually.
                          image={layout.img}
                          title={layout.title}
                          sx={(theme) => ({
                            aspectRatio: "16 / 9",
                            objectPosition: "top",
                            transition: "filter 0.3s",
                            ...theme.applyStyles("dark", {
                              content: "var(--src)",
                            }),
                          })}
                        />
                        <Button
                          variant="text"
                          endIcon={<OpenInNewRoundedIcon />}
                          component={Link}
                          target="_blank"
                          href={layout.href}
                          data-ga-event-category="material-ui-template"
                          data-ga-event-label={layout.title}
                          data-ga-event-action="preview-img"
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            opacity: 0,
                            transition: "opacity 0.5s ease",
                            backgroundColor: "background.paper",
                            "&:hover": {
                              backgroundColor: "background.default",
                            },
                          }}
                        >
                          See live preview
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          p: 2,
                          pt: 1.5,
                          borderTop: "1px solid",
                          borderColor: "divider",
                        }}
                      >
                        <Stack
                          direction="row"
                          sx={{ justifyContent: "space-between" }}
                        >
                          <Typography
                            component="h3"
                            variant="subtitle1"
                            gutterBottom
                            sx={{ fontWeight: "semiBold" }}
                          >
                            {layout.title}
                          </Typography>
                          <Typography
                            component="h3"
                            variant="h4"
                            gutterBottom
                            sx={{ fontWeight: "bold", color: "primary.light" }}
                          >
                            {`${currency}${layout.price[country]}`}
                          </Typography>
                        </Stack>

                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          sx={{ color: "text.secondary", mb: 2 }}
                        >
                          {layout.description}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            gap: 1,
                            mt: "auto",
                          }}
                        >
                          <Button
                            component="a"
                            href={layout.href}
                            size="small"
                            // fullWidth
                            target="_blank"
                            sx={{ width: "50%" }}
                            variant="contained"
                            color="secondary"
                            startIcon={<Visibility sx={{ mr: 0.5 }} />}
                            data-ga-event-category="material-ui-template"
                            data-ga-event-label={layout.title}
                            data-ga-event-action="preview-img"
                          >
                            Live preview
                          </Button>
                          <RLink
                            style={{ width: "50%" }}
                            to={`/getTemplateForm?templt=${index}`}
                          >
                            <Button
                              size="small"
                              fullWidth
                              variant="contained"
                              color="secondary"
                              startIcon={<GetApp sx={{ mr: 0.5 }} />}
                            >
                              Get Template
                            </Button>
                          </RLink>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </>
      )}
    </>
  );
}
