import { Container, Typography } from "@mui/material";
import React from "react";
import Hero from "./Hero";
import AppAppBar from "./AppAppBar";

const About = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AppAppBar temp="2" />
      <Hero temp="2" />
      <Container sx={{ pb: 2 }}>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            fontWeight: "400",
            color: "text.secondary",
            flex: 1,
            textAlign: "justify", // Justified text for better readability
          }}
        >
          Hello I am Pratik Shinde, a dedicated and passionate developer with a
          strong foundation in creating dynamic and user-friendly applications.
          I completed my BTech, which equipped me with a solid technical
          background and a deep understanding of computer science principles.
          Over the years, I have honed my skills in various technologies,
          particularly in React, Node.js, MUI, CSS, and numerous other front-end
          libraries that empower me to design modern and visually appealing user
          interfaces.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            fontWeight: "400",
            color: "text.secondary",
            flex: 1,
            textAlign: "justify",
          }}
        >
          During my time at Refast, a dynamic startup, I had the privilege of
          working alongside a talented team where I played a key role in
          developing innovative solutions that addressed real-world challenges.
          My responsibilities included collaborating on projects from conception
          to execution, ensuring that we delivered not only functional but also
          aesthetically pleasing products. This experience taught me the
          importance of agility and adaptability in a fast-paced environment,
          and I thrived in the collaborative atmosphere that fostered creativity
          and innovation.
        </Typography>

        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            fontWeight: "400",
            color: "text.secondary",
            textAlign: "justify",
          }}
        >
          Currently, I am advancing my career as an Assistant System Engineer at
          a leading MNC in India. In this role, I have the opportunity to work
          on large-scale projects that require robust back-end systems and
          seamless integrations. This position has further strengthened my
          technical expertise, as I am constantly learning and applying new
          concepts to enhance our solutions. I take pride in my ability to
          troubleshoot issues effectively and optimize performance, ensuring
          that our applications run smoothly and efficiently.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            fontWeight: "400",
            color: "text.secondary",
            textAlign: "justify",
          }}
        >
          With a keen eye for detail and a commitment to excellence, I strive to
          deliver high-quality products that not only meet client expectations
          but also provide an exceptional user experience. I believe that the
          user interface is crucial in determining how users interact with a
          product, and I am dedicated to creating designs that are intuitive,
          engaging, and accessible to all users. Whether it's developing modern
          user interfaces or building robust back-end systems, I am passionate
          about leveraging technology to solve real-world problems.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            fontWeight: "400",
            color: "text.secondary",
            textAlign: "justify",
          }}
        >
          In addition to my technical skills, I am an advocate for continuous
          learning and personal development. I actively seek out opportunities
          to expand my knowledge and stay updated on industry trends. I am also
          enthusiastic about sharing my insights with others, whether through
          mentoring or participating in community discussions. I firmly believe
          that collaboration and knowledge-sharing can lead to innovative
          solutions that benefit everyone.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            fontWeight: "400",
            color: "text.secondary",
            textAlign: "justify",
          }}
        >
          As I continue my journey as a developer, I am excited about the
          endless possibilities technology offers and look forward to
          contributing to projects that make a positive impact. I am eager to
          connect with like-minded individuals and organizations that share my
          passion for technology and innovation.
        </Typography>
      </Container>
    </>
  );
};

export default About;
