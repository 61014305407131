export const templates = [
  {
    title: "Elegant Professional Portfolio",
    description: `A modern and visually stunning portfolio template, perfect for creative professionals. The sleek dark theme with neon accents makes it ideal for showcasing personal branding. The bold "Contact Me" and "Let's Talk" buttons ensure easy communication with potential clients. Suitable for individuals looking to stand out with a clean, creative, and elegant design.`,
    href: "https://pratikshinde.in",
    img: "./images/premium.png",
    price: { IN: 500, CA: 40, US: 30 },
    
  },
  {
    title: "Professional Minimalist Portfolio",
    description: `A sleek and minimalist design focused on showcasing your work. With easy sidebar navigation for quick access to sections like skills, projects, and services, this template is perfect for professionals wanting a simple yet impactful portfolio. Ideal for developers, freelancers, and creatives looking to highlight their expertise with a clean, functional layout.`,
    href: "https://pratikshinde.in",
    img: "./images/pro.png",
    price: { IN: 500, CA: 40, US: 30 },
    hasDarkMode: true,
  },
];

export const tiers = [
  {
    title: "Starter",
    price: { IN: "1000", CA: "50", US: "40" },
    description: [
      "1-page design",
      "Responsive layout",
      "Basic customization (colors, fonts)",
      "Fast loading",
      "2 free updates for future enhancements.",
    ],
    meetLink:
      "https://calendly.com/pratikshinde85499/starter-package-portfolio-design-consultation",
    buttonText: "Get Started",
    buttonVariant: "contained",
    buttonColor: "secondary",
  },
  {
    title: "Pro",
    subheader: "Recommended",
    price: { IN: "2000", CA: "100", US: "80" },
    description: [
      "Multi-page design (About, Projects, Contact)",
      "Customizable sections",
      "Integration with social media",
      "Animations and interactive elements",
      "5 free updates for future enhancements.",
    ],
    meetLink:
      "https://calendly.com/pratikshinde85499/pro-package-custom-design-features-consultation",
    buttonText: "Get Started",
    buttonVariant: "contained",
    buttonColor: "secondary",
  },
  {
    title: "Premium",
    price: { IN: "3000", CA: "150", US: "120" },
    description: [
      "Fully custom multi-page design",
      "Advanced animations and interactions",
      "Blog or project showcase feature",
      "Personalized branding and design elements",
      "10 free updates for future enhancements.",
    ],
    meetLink:
      "https://calendly.com/pratikshinde85499/premium-fully-customized-portfolio-consultation",
    buttonText: "Get Started",
    buttonVariant: "contained",
    buttonColor: "secondary",
  },
];
