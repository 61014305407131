import React from "react";
import { Container, Typography, Box, Divider } from "@mui/material";
import AppAppBar from "./AppAppBar";
import Hero from "./Hero";

const Terms = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AppAppBar temp="3" />
      <Hero temp="6" />
      <Container maxWidth="md" sx={{ mb: 4 }}>
        {/* Page Title */}
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Terms and Conditions
        </Typography>
        <Divider sx={{ mb: 4 }} />

        {/* Last Updated */}
        {/* <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Last Updated: [Insert Date]
        </Typography> */}

        {/* Section 1: Acceptance of Terms */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography variant="body1" gutterBottom>
            By using our Website and purchasing any services or templates, you
            agree to these Terms and Conditions in full. If you disagree with
            these terms, you should not use the Website.
          </Typography>
        </Box>

        {/* Section 2: Services */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            2. Services
          </Typography>
          <Typography variant="body1" gutterBottom>
            We provide portfolio templates, customization, and other web
            development services. All services are provided based on the
            specifications agreed upon at the time of purchase or consultation.
          </Typography>
        </Box>

        {/* Section 3: Pricing and Payments */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            3. Pricing and Payments
          </Typography>
          <Typography variant="body1" gutterBottom>
            All prices for our templates and services are listed in
            [INR/USD/CAD] and are subject to change without notice. Payment for
            services should be made in full before any work begins, unless
            otherwise agreed upon in writing.
          </Typography>
        </Box>

        {/* Section 4: Refunds */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            4. Refunds
          </Typography>
          <Typography variant="body1" gutterBottom>
            Due to the nature of our services, all sales are final. No refunds
            will be issued after purchase or commencement of the project, unless
            there is a fault on our part.
          </Typography>
        </Box>

        {/* Section 5: Template Usage */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            5. Template Usage
          </Typography>
          <Typography variant="body1" gutterBottom>
            Our templates are for personal or professional use only.
            Unauthorized distribution or commercial use is prohibited.
          </Typography>
        </Box>

        {/* Section 6: Intellectual Property */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            6. Intellectual Property
          </Typography>
          <Typography variant="body1" gutterBottom>
            All content, including designs, templates, and media, is the
            intellectual property of [Your Business Name]. You may not copy,
            reproduce, or create derivative works without permission.
          </Typography>
        </Box>

        {/* Section 7: User Responsibilities */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            7. User Responsibilities
          </Typography>
          <Typography variant="body1" gutterBottom>
            Users agree to provide accurate information and comply with
            applicable laws when using our services.
          </Typography>
        </Box>

        {/* Section 8: Limitation of Liability */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            8. Limitation of Liability
          </Typography>
          <Typography variant="body1" gutterBottom>
            We are not liable for damages or losses arising from the use of our
            Website or services.
          </Typography>
        </Box>

        {/* Section 9: Modifications */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            9. Modifications
          </Typography>
          <Typography variant="body1" gutterBottom>
            We reserve the right to modify these Terms at any time. Continued
            use signifies acceptance of any changes.
          </Typography>
        </Box>

        {/* Section 10: Contact Us */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            10. Contact Us
          </Typography>
          <Typography variant="body1" gutterBottom>
            If you have any questions, feel free to contact us at
            pratikshinde85499@gmail.com .
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default Terms;
