import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { Context } from "../context/context";

export default function Pricing() {
  const { currency, tiers, pkgPricing } = React.useContext(Context);
  // const country = "IN";
  // const currency = "₹";

  return (
    <Container
      id="pricing"
      sx={{
        py: 16,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: "text.primary" }}
        >
          Pricing
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          Choose the portfolio package that fits your needs and budget. From
          basic to fully customized designs, we’ve got you covered.
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{ alignItems: "center", justifyContent: "center", width: "100%" }}
      >
        {tiers.map((tier, index) => (
          <Grid
            size={{ xs: 12, sm: tier.title === "Premium" ? 12 : 6, md: 4 }}
            key={tier.title}
          >
            <Card
              sx={[
                {
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                },
                // tier.title === "Pro" &&
                (theme) => ({
                  border: "none",
                  background:
                    "radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))",
                  boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                  ...theme.applyStyles("dark", {
                    background:
                      "radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))",
                    boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                  }),
                }),
              ]}
            >
              <CardContent>
                <Box
                  sx={[
                    {
                      mb: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2,
                    },
                    tier.title === "Pro"
                      ? { color: "grey.100" }
                      : { color: "" },
                  ]}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {/* {tier.title === "Pro" && (
                    <Chip icon={<AutoAwesomeIcon />} label={tier.subheader} />
                  )} */}
                </Box>
                <Box
                  sx={[
                    {
                      display: "flex",
                      alignItems: "baseline",
                    },
                    tier.title === "Pro"
                      ? { color: "grey.50" }
                      : { color: null },
                  ]}
                >
                  <Typography component="h3" variant="h2">
                    {pkgPricing &&
                      `${currency}${pkgPricing[tier.title.toLowerCase()]}`}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp; per project
                  </Typography>
                </Box>
                <Divider sx={{ my: 2, opacity: 0.8, borderColor: "divider" }} />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={[
                        {
                          width: 20,
                        },
                        tier.title === "Pro"
                          ? { color: "primary.light" }
                          : { color: "primary.main" },
                      ]}
                    />
                    <Typography
                      variant="subtitle2"
                      component={"span"}
                      sx={[
                        tier.title === "Pro"
                          ? { color: "grey.50" }
                          : { color: null },
                      ]}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Link style={{ width: "100%" }} to={`/getStarted?pkg=${index}`}>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant}
                    color={tier.buttonColor}
                  >
                    {tier.buttonText}
                  </Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        ))}
        <Stack
          mt={3}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            OR
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary", my: 1 }}>
            Explore our selection of pre-built portfolio templates.
          </Typography>
          <Link to="/templates?temp=1">
            <Button variant="contained" sx={{ mt: 2 }} color="secondary">
              View Templates
            </Button>
          </Link>
        </Stack>
      </Grid>
    </Container>
  );
}
