import React from "react";
import { Skeleton, Box, Grid, Container } from "@mui/material";

const TemplateSkeleton = () => {
  return (
    <Container sx={{ padding: "2rem", pt: 16 }}>
      {/* Hero Section Skeleton */}
      <Box sx={{ textAlign: "center", marginBottom: "3rem" }}>
        <Skeleton
          variant="text"
          width="40%"
          height={60}
          sx={{ margin: "auto" }}
        />
        <Skeleton
          variant="text"
          width="60%"
          height={40}
          sx={{ margin: "1rem auto" }}
        />
      </Box>

      {/* Grid for Template Cards */}
      <Grid container spacing={3}>
        {/* First Card Skeleton */}
        <Grid item xs={12} sm={6} md={6}>
          <Skeleton variant="rectangular" height={200} width="100%" />
          <Skeleton variant="text" width="60%" sx={{ marginY: "1rem" }} />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="80%" />
          <Skeleton
            variant="rectangular"
            height={40}
            width="50%"
            sx={{ marginY: "1rem" }}
          />
        </Grid>

        {/* Second Card Skeleton */}
        <Grid item xs={12} sm={6} md={6}>
          <Skeleton variant="rectangular" height={200} width="100%" />
          <Skeleton variant="text" width="60%" sx={{ marginY: "1rem" }} />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="80%" />
          <Skeleton
            variant="rectangular"
            height={40}
            width="50%"
            sx={{ marginY: "1rem" }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default TemplateSkeleton;
