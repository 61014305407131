import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid2 } from "@mui/material";
import { SiMui } from "react-icons/si";
import { IoLogoNodejs } from "react-icons/io5";
import { RiTailwindCssFill } from "react-icons/ri";
import { FaReact } from "react-icons/fa";

const iconStyle = {
  width: "70px",
  height: "70px",
  margin: "0 32px",
  opacity: 0.7,
};

export default function LogoCollection() {
  const data = [
    {
      href: "https://en.wikipedia.org/wiki/React_(JavaScript_library)",
      icon: <FaReact key="vjfh" style={iconStyle} />,
    },
    {
      href: "https://mui.com/about/",
      icon: <SiMui key="vjf2" style={iconStyle} />,
    },
    {
      href: "https://en.wikipedia.org/wiki/Node.js",
      icon: <IoLogoNodejs key="vjf534" style={iconStyle} />,
    },
    {
      href: "https://en.wikipedia.org/wiki/Tailwind_CSS",
      icon: <RiTailwindCssFill key="v435jfh" style={iconStyle} />,
    },
  ];
  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="subtitle1"
        align="center"
        sx={{ color: "text.secondary" }}
      >
        Technology Stack
      </Typography>
      <Grid2 container sx={{ justifyContent: "center", opacity: 0.6, mt: 8 }}>
        {data.map(
          (item, index) => (
            // index > 1 ? (
            //   <Grid2 sx={{ mt: { xs: 6, md: 0 } }} item key={index}>
            //     {icon}
            //   </Grid2>
            // ) : (
            <Grid2 my={4} item key={index}>
              <a target="_blank" style={{ color: "inherit" }} href={item.href}>
                {item.icon}
              </a>
            </Grid2>
          )
          // )
        )}
      </Grid2>
    </Box>
  );
}
