import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Instagram } from "@mui/icons-material";
import { Link as MuiLink } from "@mui/material/";
import { Link } from "react-router-dom";

// function Copyright() {
//   return (
//     <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
//       {'Copyright © '}
//       <Link color="text.secondary" href="https://mui.com/">
//         Sitemark
//       </Link>
//       &nbsp;
//       {new Date().getFullYear()}
//     </Typography>
//   );
// }

export default function Footer() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          // display: "flex",
          // justifyContent: "space-between",
          // pt: { xs: 4, },
          width: "100%",
        }}
      >
        <div>
          <Link style={{ textDecoration: "none" }} to="/privacyPolicy">
            <MuiLink color="text.secondary" variant="body2">
              Privacy Policy
            </MuiLink>
          </Link>
          <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link style={{ textDecoration: "none" }} to="/terms">
            <MuiLink color="text.secondary" variant="body2">
              Terms of Service
            </MuiLink>
          </Link>
          {/* <Copyright /> */}
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          mt={2}
          sx={{ justifyContent: "left", color: "text.secondary" }}
        >
          <IconButton
            color="inherit"
            size="small"
            target="_blank"
            href="https://github.com/pratik7262?tab=repositories"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            target="_blank"
            href="https://www.instagram.com/pratik_72.62/"
            aria-label="X"
            sx={{ alignSelf: "center" }}
          >
            <Instagram />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            target="_blank"
            href="https://www.linkedin.com/in/pratik-shinde-156993213"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
