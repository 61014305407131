import { Divider } from "@mui/material";
import Footer from "./components/Footer";
import { Route, Routes } from "react-router-dom";
import Templates from "./components/Templates";
import Main from "./components/Main";
import About from "./components/About";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";
import NotFound from "./components/NotFound";
import GetStarted from "./components/GetStarted";
import GetTemplateForm from "./components/GetTemplateForm";
import { useTemplates } from "./hooks/useTemplates";
import { useCountry } from "./hooks/useCountry";

function App() {
  // const [mode, setMode] = React.useState("light");
  // const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  // const MPTheme = createTheme(getMPTheme(mode));
  // const defaultTheme = createTheme({ palette: { mode } });
  useTemplates();
  useCountry();
  return (
    <>
      <Routes>
        <Route element={<Main />} path="/" />
        <Route element={<Templates />} path="/templates" />
        <Route element={<About />} path="/about" />
        <Route element={<Terms />} path="/terms" />
        <Route element={<PrivacyPolicy />} path="/privacyPolicy" />
        <Route element={<GetStarted />} path="/getStarted" />
        <Route element={<GetTemplateForm />} path="/getTemplateForm" />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Divider />
      <Footer />
    </>
  );
}

export default App;
