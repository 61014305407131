import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: "blur(24px)",
  border: "1px solid",
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: "8px 12px",
}));

export default function AppAppBar({ temp }) {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const tempStyle = { display: temp ? "none" : "" };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        mt: 5,
      }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box
            sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0 }}
          >
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Link to="/">
                <Button
                  sx={{ display: temp ? "" : "none" }}
                  variant="text"
                  color="info"
                  size="large"
                >
                  Home
                </Button>
              </Link>
              <a href="#hero">
                <Button sx={tempStyle} variant="text" color="info" size="large">
                  Home
                </Button>
              </a>
              <a href="#features">
                <Button sx={tempStyle} variant="text" color="info" size="large">
                  Features
                </Button>
              </a>
              <a href="#highlights">
                <Button sx={tempStyle} variant="text" color="info" size="large">
                  Highlights
                </Button>
              </a>
              <a href="#pricing">
                <Button sx={tempStyle} variant="text" color="info" size="large">
                  Pricing
                </Button>
              </a>
              <a href="#faq">
                <Button sx={tempStyle} variant="text" color="info" size="large">
                  FAQs
                </Button>
              </a>
              <a href="#contact">
                <Button
                  variant="text"
                  color="info"
                  size="large"
                  sx={{ minWidth: 0, ...tempStyle }}
                >
                  Contact
                </Button>
              </a>
              <Link to="/templates">
                <Button variant="text" color="info" size="large">
                  Templates
                </Button>
              </Link>
              <Link to="/about">
                <Button variant="text" color="info" size="large">
                  About Me
                </Button>
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 1,
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontFamily: "Playfair Display",
                fontWeight: "bold",
                fontSize: "1.875rem",
              }}
              color="primary.dark"
            >
              PS
            </Typography>
          </Box>
          <Box sx={{ display: { sm: "flex", md: "none" } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: "background.default" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/"
                  onClick={toggleDrawer(false)}
                >
                  <MenuItem sx={{ display: temp ? "" : "none" }}>Home</MenuItem>
                </Link>
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="#hero"
                  onClick={toggleDrawer(false)}
                >
                  <MenuItem sx={tempStyle}>Home</MenuItem>
                </a>
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="#features"
                  onClick={toggleDrawer(false)}
                >
                  <MenuItem sx={tempStyle}>Features</MenuItem>
                </a>
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="#highlights"
                  onClick={toggleDrawer(false)}
                >
                  <MenuItem sx={tempStyle}>Highlights</MenuItem>
                </a>
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="#pricing"
                  onClick={toggleDrawer(false)}
                >
                  <MenuItem sx={tempStyle}>Pricing</MenuItem>
                </a>
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="#faq"
                  onClick={toggleDrawer(false)}
                >
                  <MenuItem sx={tempStyle}>FAQs</MenuItem>
                </a>
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="#contact"
                  onClick={toggleDrawer(false)}
                >
                  <MenuItem sx={tempStyle}>Contact</MenuItem>
                </a>
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/templates"
                  onClick={toggleDrawer(false)}
                >
                  <MenuItem>Templates</MenuItem>
                </Link>
                <Link
                  to="/about"
                  style={{ textDecoration: "none", color: "white" }}
                  onClick={toggleDrawer(false)}
                >
                  <MenuItem>About Me</MenuItem>
                </Link>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
