import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// import { styled } from "@mui/material/styles";

// const StyledBox = styled("div")(({ theme }) => ({
//   alignSelf: "center",
//   width: "100%",
//   height: 400,
//   marginTop: theme.spacing(8),
//   borderRadius: theme.shape.borderRadius,
//   outline: "6px solid",
//   outlineColor: "hsla(220, 25%, 80%, 0.2)",
//   border: "1px solid",
//   borderColor: theme.palette.grey[200],
//   boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
//   backgroundImage: `url(${"/static/screenshots/material-ui/getting-started/templates/dashboard.jpg"})`,
//   backgroundSize: "cover",
//   [theme.breakpoints.up("sm")]: {
//     marginTop: theme.spacing(10),
//     height: 700,
//   },
//   ...theme.applyStyles("dark", {
//     boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
//     backgroundImage: `url(${"https://pratikshinde.in/images/ReactAdminDashboard/5.png"})`,
//     outlineColor: "hsla(220, 20%, 42%, 0.1)",
//     borderColor: theme.palette.grey[700],
//   }),
// }));

const Hero = ({ temp }) => {
  const tempStyle = { display: temp ? "none" : "" };

  const startChat = () => {
    window.open(
      "https://tawk.to/chat/67064c61cec6d0125df3c59e/1i9p5m9ob",
      "_blank"
    );
  };

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)",
        ...theme.applyStyles("dark", {
          backgroundImage:
            "radial-gradient(ellipse 80% 120% at 50% -20%, hsl(210, 100%, 16%), transparent)",
        }),
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: temp === "6" ? 6 : { xs: 14, sm: 20 },
          pb: temp === "2" || temp === "4" ? 2 : { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{
            alignItems: "center",
            width: { xs: "100%", sm: "70%" },
            ...tempStyle,
          }}
        >
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              fontSize: "clamp(2.5rem, 8vw, 4rem)",
            }}
          >
            Start&nbsp;Building&nbsp;Your&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: "inherit",
                color: "primary.main",
                ...theme.applyStyles("dark", {
                  color: "primary.light",
                }),
              })}
            >
              Personal&nbsp;Brand&nbsp;
            </Typography>
            Today&nbsp;
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "text.secondary",
              fontSize: { xs: "0.875rem", md: "1.125rem" },
              width: { xs: "100%", sm: "80%", md: "70%" },
            }}
          >
            It’s never too early to showcase your skills and achievements. Get
            your own custom portfolio website now.
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            useFlexGap
            sx={{
              pt: 2,
              width: { xs: "100%", sm: "350px" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={startChat}
              sx={{
                width: { xs: "100%", sm: "auto" },
                minWidth: { xs: "100%", sm: "auto" },
              }}
            >
              Start Conversation
            </Button>
          </Stack>
        </Stack>
        <Stack
          spacing={2}
          useFlexGap
          sx={{
            alignItems: "center",
            width: { xs: "100%", sm: "70%" },
            display: temp === "1" ? "" : "none",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              fontSize: "clamp(2.5rem, 8vw, 4rem)",
            }}

            // Explore Our Portfolio Designs
          >
            Explore&nbsp;Our&nbsp;Portfolio&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: "inherit",
                color: "primary.main",
                ...theme.applyStyles("dark", {
                  color: "primary.light",
                }),
              })}
            >
              Design&nbsp;Templates&nbsp;
            </Typography>
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "text.secondary",
              fontSize: { xs: "0.875rem", md: "1.125rem" },
              width: { xs: "100%", sm: "80%", md: "70%" },
            }}
          >
            Discover a range of unique, professional portfolio templates, and
            have your portfolio crafted in your favorite design.
          </Typography>
          {/* <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            useFlexGap
            sx={{
              pt: 2,
              width: { xs: "100%", sm: "350px" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              sx={{
                width: { xs: "100%", sm: "auto" },
                minWidth: { xs: "100%", sm: "auto" },
              }}
            >
              Start Conversation
            </Button>
          </Stack> */}
        </Stack>
        <Stack
          spacing={2}
          useFlexGap
          sx={{
            alignItems: "center",
            width: { xs: "100%", sm: "70%" },
            display: temp === "2" ? "" : "none",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              fontSize: "clamp(2.5rem, 8vw, 4rem)",
            }}

            // Hi, I'm [Your Name]
          >
            So,&nbsp;Who&nbsp;Am&nbsp;I&nbsp;?
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: "inherit",
                color: "primary.main",
                ...theme.applyStyles("dark", {
                  color: "primary.light",
                }),
              })}
            >
              {/* So,&nbsp;Who&nbsp;Am&nbsp;I&nbsp;? */}
            </Typography>
          </Typography>
          {/* <Typography
            sx={{
              textAlign: "center",
              color: "text.secondary",
              fontSize: { xs: "0.875rem", md: "1.125rem" },
              width: { xs: "100%", sm: "80%", md: "70%" },
            }}
          >
            A Passionate Developer Committed to Crafting Modern Solutions With A
            Strong Foundation In Creating Dynamic And User-Friendly
            Applications.
          </Typography> */}
          {/* <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            useFlexGap
            sx={{
              pt: 2,
              width: { xs: "100%", sm: "350px" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              sx={{
                width: { xs: "100%", sm: "auto" },
                minWidth: { xs: "100%", sm: "auto" },
              }}
            >
              Start Conversation
            </Button>
          </Stack> */}
        </Stack>
      </Container>
    </Box>
  );
};

export default Hero;
