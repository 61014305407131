import React, { useEffect } from "react";
import LogoCollection from "./LogoCollection";
import Features from "./Features";
import { Divider } from "@mui/material";
import Highlights from "./Highlights";
import Pricing from "./Pricing";
import FAQ from "./FAQ";
import Contact from "./Contact";
import Hero from "./Hero";
import AppAppBar from "./AppAppBar";

const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AppAppBar temp={null} />
      <Hero temp={null} />
      <div>
        <LogoCollection />
        <Features />
        {/* <Divider />
        <Testimonials />*/}
        <Divider />
        <Highlights />
        <Divider />
        <Pricing />
        <Divider />
        <FAQ />
        <Divider />
        <Contact />
      </div>
    </>
  );
};

export default Main;
