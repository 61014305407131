import { useContext, useEffect } from "react";
import { Context } from "../context/context";

export const useTemplates = () => {
  const { setTemplates } = useContext(Context);
  const fetchTemplates = async () => {
    const res = await fetch(
      "https://script.google.com/macros/s/AKfycbyQxeYJO2-QmPvXfI3_7VGPjjpUyUiRFwT8xHc30hjBt4yg0prvEcumloZGCKPfDnsb/exec"
    );

    const data = await res.json();

    setTemplates(data);
  };
  useEffect(() => {
    fetchTemplates();
    // eslint-disable-next-line
  }, []);
};
