import React, { useState } from "react";
import { TextField, Button, Box, Typography, Container } from "@mui/material";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [emailError, setEmailError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.name === "email") {
      validateEmail(e.target.value);
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!emailError && formData.email) {
      const formElement = document.createElement("form");
      formElement.action =
        "https://formsubmit.co/196ad7fb7ce2142f566446659ed4b902";
      formElement.method = "POST";

      Object.keys(formData).forEach((key) => {
        const input = document.createElement("input");
        input.name = key;
        input.value = formData[key];
        formElement.appendChild(input);
      });

      document.body.appendChild(formElement);
      formElement.submit();
    }
  };

  return (
    <Container
      id="contact"
      sx={{
        py: 16,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: "text.primary" }}
        >
          Let’s Start Your Journey
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          Ready to build your portfolio? Fill out the form below, and let's
          discuss how we can showcase your skills and create opportunities!
        </Typography>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          maxWidth: 400,
          margin: "auto",
        }}
      >
        <TextField
          id="name-hero"
          hiddenLabel
          size="small"
          variant="outlined"
          placeholder="Your name"
          fullWidth
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          slotProps={{
            htmlInput: {
              autoComplete: "off",
              "aria-label": "Enter your name",
            },
          }}
        />

        <TextField
          id="email-hero"
          hiddenLabel
          size="small"
          variant="outlined"
          placeholder="Your email address"
          fullWidth
          name="email"
          value={formData.email}
          onChange={handleChange}
          error={!!emailError}
          helperText={emailError}
          required
          slotProps={{
            htmlInput: {
              autoComplete: "off",
              "aria-label": "Enter your email address",
            },
          }}
        />

        <TextField
          id="message-hero"
          hiddenLabel
          size="small"
          variant="outlined"
          placeholder="Your message"
          fullWidth
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          slotProps={{
            htmlInput: {
              autoComplete: "off",
              "aria-label": "Enter your message here",
            },
          }}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={!!emailError}
        >
          Send Message
        </Button>
      </Box>
    </Container>
  );
};

export default Contact;
