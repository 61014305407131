import { createContext, useState } from "react";
import { tiers } from "./data";

const Context = createContext();

const ContextProvider = ({ children }) => {
  // Fixed here
  const url =
    "https://api.ipgeolocation.io/ipgeo?apiKey=8c0ac1cc1a0549d7b2a083c929fdeb62";
  const [country, setCountry] = useState(localStorage.getItem("jkjnde"));
  const [currency, setCurrency] = useState(localStorage.getItem("kbfdk"));
  const [templates, setTemplates] = useState([]);
  const [pkgPricing, setPkgPricing] = useState(
    JSON.parse(localStorage.getItem("fvdhvnsdygds"))
  );
  const countryRows = { IN: 1, CA: 2, US: 3 };

  return (
    <Context.Provider
      value={{
        country,
        currency,
        tiers,
        templates,
        pkgPricing,
        countryRows,
        url,
        setCountry,
        setCurrency,
        setPkgPricing,
        setTemplates,
      }}
    >
      {children} {/* Fixed here */}
    </Context.Provider>
  );
};

export { Context, ContextProvider };
