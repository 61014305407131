import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  CircularProgress,
} from "@mui/material";
import { Context } from "../context/context";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppAppBar from "./AppAppBar";
import Hero from "./Hero";

const GetStarted = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { country, currency, tiers } = React.useContext(Context);
  const [searchParams] = useSearchParams();
  const pkgIndex = searchParams.get("pkg");
  const selectedPackage = tiers[Number(pkgIndex)];

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      await fetch(
        "https://script.google.com/macros/s/AKfycbyfXBqG3ElP7TGkwXFi15hudnUFQFcxogBj2zv7OqQoNb40UCRbDvqXBrtha2jk_jS4/exec",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            name: name,
            email: email,
            message: message,
            package: selectedPackage.title,
            price: `${currency}${selectedPackage.price[country]}`,
            country: country,
          }),
        }
      );

      // setIsSubmitted(true);
      setEmail("");
      setName("");
      setMessage("");
      setLoading(false);
      toast.success(
        "Thank you! Your message has been received. I will connect with you soon."
      );
    } catch (error) {
      setLoading(false);
      toast.error(" Sorry! Something went wrong please try again.", {
        style: {
          backgroundColor: "#2b2b2b", // Theme background
          color: "#fff", // Text color
          fontWeight: "bold",
          borderRadius: "8px",
          padding: "16px",
        },
      });
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scheduleMeeting = () => {
    // Open scheduling link (replace with your actual scheduling link)
    window.open(selectedPackage.meetLink, "_blank");
  };

  const startChat = () => {
    window.open(
      "https://tawk.to/chat/67064c61cec6d0125df3c59e/1ia26ttug",
      "_blank"
    );
  };

  return (
    <>
      <AppAppBar temp />
      <Hero temp="4" />
      <Container maxWidth="sm" sx={{ pb: 8 }}>
        <Typography
          variant="h4"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            fontSize: {
              xs: (theme) => theme.typography.pxToRem(16), // Small screens
              sm: (theme) => theme.typography.pxToRem(20), // Medium screens
              md: (theme) => theme.typography.pxToRem(24), // Large screens (max size)
            },
          }}
        >
          Get&nbsp;Started&nbsp;with&nbsp;
          <Typography
            component="span"
            variant="h4"
            sx={(theme) => ({
              fontSize: "inherit",
              color: "primary.main",
              [theme.breakpoints.down("sm")]: {
                color: "primary.light",
              },
            })}
          >
            {selectedPackage.title}&nbsp;Package
          </Typography>
        </Typography>
        <Typography variant="h6" gutterBottom>
          Price: {`${currency}${selectedPackage.price[country]}`}
        </Typography>

        {/* Button for scheduling a meeting */}
        <Button
          variant="contained"
          color="primary"
          onClick={scheduleMeeting}
          sx={{ mb: 3 }}
        >
          Schedule a Meeting
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={startChat}
          sx={{ mb: 3, ml: 2 }}
        >
          Start Live Chat
        </Button>
        {/* Form for getting started */}
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            id="name-gs"
            hiddenLabel
            size="small"
            variant="outlined"
            placeholder="Name"
            fullWidth
            name="name"
            sx={{ border: "1px solid white", borderRadius: 1 }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            slotProps={{
              htmlInput: {
                autoComplete: "off",
                "aria-label": "Enter name",
              },
            }}
          />
          <TextField
            id="email-gs"
            hiddenLabel
            size="small"
            variant="outlined"
            placeholder="Email"
            fullWidth
            name="email"
            type="email"
            sx={{ my: 2, border: "1px solid white", borderRadius: 1 }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            slotProps={{
              htmlInput: {
                autoComplete: "off",
                "aria-label": "Enter name",
              },
            }}
          />
          <TextField
            id="message-gs"
            hiddenLabel
            size="small"
            variant="outlined"
            placeholder="Additional Message"
            fullWidth
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            sx={{ border: "1px solid white", borderRadius: 1 }}
            slotProps={{
              htmlInput: {
                autoComplete: "off",
                "aria-label": "Enter message",
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            {loading ? (
              <CircularProgress color="#05070a" size={24} />
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </Container>
      <ToastContainer />
    </>
  );
};

export default GetStarted;
