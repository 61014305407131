import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PaletteIcon from "@mui/icons-material/Palette";
import GppGoodIcon from "@mui/icons-material/GppGood";
import DevicesIcon from "@mui/icons-material/Devices";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import SpeedIcon from "@mui/icons-material/Speed";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

const items = [
  {
    icon: <PaletteIcon />,
    title: "Customizable Designs",
    description:
      "Tailor your portfolio to your unique style with our flexible and creative templates, designed to leave a strong first impression.",
  },
  {
    icon: <GppGoodIcon />,
    title: "Secure and Reliable",
    description:
      "Our portfolio websites ensure top-notch security and reliability, protecting your data and showcasing your work smoothly.",
  },
  {
    icon: <DevicesIcon />,
    title: "Responsive Layouts",
    description:
      "Built with mobile-friendly, adaptive designs that look stunning on any device, offering a seamless user experience.",
  },
  {
    icon: <BrowserUpdatedIcon />,
    title: "Easy to Maintain",
    description:
      "Keep your portfolio updated effortlessly with intuitive management tools, perfect for keeping your content fresh.",
  },
  {
    icon: <SpeedIcon />,
    title: "Optimized for Performance",
    description:
      "Experience fast load times and smooth navigation, ensuring your potential clients have a flawless browsing experience.",
  },
  {
    icon: <SupportAgentIcon />,
    title: "Ongoing Support",
    description:
      "Receive dedicated customer support, ensuring your portfolio site remains functional and relevant at all times.",
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: 16,
        pb: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: "grey.900",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Highlights
          </Typography>
          <Typography variant="body1" sx={{ color: "grey.400" }}>
            Explore why our portfolio websites are the perfect choice for
            professionals looking to make a lasting impression.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: "inherit",
                  p: 3,
                  height: "100%",
                  borderColor: "hsla(220, 25%, 25%, 0.3)",
                  backgroundColor: "grey.800",
                }}
              >
                <Box sx={{ opacity: "50%" }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: "medium" }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey.400" }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
