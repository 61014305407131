import React, { useContext, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSearchParams } from "react-router-dom";
import { Context } from "../context/context";
import AppAppBar from "./AppAppBar";
import Hero from "./Hero";
import { useTemplates } from "../hooks/useTemplates";

const GetTemplateForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [customization, setCustomization] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { country, currency, templates } = useContext(Context);
  const [searchParams] = useSearchParams();
  const templateIndex = searchParams.get("templt");
  const selectedTemplate = templates[Number(templateIndex)];

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycby3yUZtYc5got0usBzHOpoWPeH-j2pHOkxLPBEDdbt_oE2UJfAa5mkL3-tK3DlqbBqlJA/exec", // Replace with your actual script URL
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            template: selectedTemplate.title,
            name: name,
            email: email,
            customization: customization,
            message: message,
            price: `${currency}${selectedTemplate.price[country]}`,
            country: country,
          }),
        }
      );

      const data = await response.json();
      console.log(data); // You can handle success or failure here
      setLoading(false);
      if (data.result === "success") {
        setName("");
        setEmail("");
        setCustomization("");
        setMessage("");
        toast.success(
          "Thank you! Your message has been received. I will connect with you soon.",
          {
            style: {
              backgroundColor: "#2b2b2b", // Theme background
              color: "#fff", // Text color
              fontWeight: "bold",
              borderRadius: "8px",
              padding: "16px",
            },
          }
        );
      } else {
        toast.error(" Sorry! Something went wrong please try again.", {
          style: {
            backgroundColor: "#2b2b2b", // Theme background
            color: "#fff", // Text color
            fontWeight: "bold",
            borderRadius: "8px",
            padding: "16px",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(" Sorry! Something went wrong please try again.", {
        style: {
          backgroundColor: "#2b2b2b", // Theme background
          color: "#fff", // Text color
          fontWeight: "bold",
          borderRadius: "8px",
          padding: "16px",
        },
      });
    }
  };

  const startChat = () => {
    window.open(
      "https://tawk.to/chat/67064c61cec6d0125df3c59e/1ia3g3a0a",
      "_blank"
    );
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useTemplates();

  return (
    <>
      <AppAppBar temp />
      <Hero temp="4" />
      {selectedTemplate ? (
        <Container maxWidth="sm">
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              // marginTop: 4,
              pb: 8,
            }}
          >
            {/* <Typography variant="h4" gutterBottom>
            Get Your Fully Customized {selectedTemplate.title} Template
          </Typography> */}
            <Typography
              variant="h4"
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                fontSize: { xs: "1rem", sm: "2rem", md: "24px" }, // Limit max size to pxToRem(24)
              }}
            >
              Get&nbsp;Your&nbsp;Fully&nbsp;Customized&nbsp;
              {selectedTemplate.title.split(" ").map((item, index) => (
                <Typography
                  component="span"
                  variant="h4"
                  key={index}
                  sx={(theme) => ({
                    fontSize: "inherit",
                    display: "flex",
                    color: "primary.main",
                    [theme.breakpoints.down("sm")]: {
                      color: "primary.light", // Change color on small screens
                    },
                  })}
                >
                  {item}&nbsp;
                </Typography>
              ))}
            </Typography>

            <Typography variant="h6" gutterBottom>
              Price: {`${currency}${selectedTemplate.price[country]}`}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={startChat}
              sx={{ mb: 3, width: "40%" }}
            >
              Start Live Chat
            </Button>
            <TextField
              id="name-gt"
              hiddenLabel
              size="small"
              variant="outlined"
              placeholder="Name"
              fullWidth
              name="name"
              sx={{ border: "1px solid white", borderRadius: 1 }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              slotProps={{
                htmlInput: {
                  autoComplete: "off",
                  "aria-label": "Enter name",
                },
              }}
            />

            {/* Email Field */}
            <TextField
              id="email-gt"
              hiddenLabel
              size="small"
              variant="outlined"
              placeholder="Email"
              fullWidth
              name="email"
              type="email"
              sx={{ my: 2, border: "1px solid white", borderRadius: 1 }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              slotProps={{
                htmlInput: {
                  autoComplete: "off",
                  "aria-label": "Enter email",
                },
              }}
            />

            {/* Customization Field */}
            {/* <TextField
            label="Customization Suggestion"
            value={customization}
            onChange={(e) => setCustomization(e.target.value)}
            variant="outlined"
            fullWidth
            required
          /> */}
            <TextField
              id="customization-gt"
              hiddenLabel
              size="small"
              variant="outlined"
              placeholder="Customization Suggestion"
              fullWidth
              name="customization"
              value={customization}
              onChange={(e) => setCustomization(e.target.value)}
              sx={{ mb: 2, border: "1px solid white", borderRadius: 1 }}
              slotProps={{
                htmlInput: {
                  autoComplete: "off",
                  "aria-label": "Enter Customization Suggesstions",
                },
              }}
            />

            {/* Message Field */}
            <TextField
              id="message-gt"
              hiddenLabel
              size="small"
              variant="outlined"
              placeholder="Any Message"
              fullWidth
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              sx={{ border: "1px solid white", borderRadius: 1 }}
              slotProps={{
                htmlInput: {
                  autoComplete: "off",
                  "aria-label": "Enter message",
                },
              }}
            />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              size="large"
            >
              {loading ? (
                <CircularProgress size={24} color="#05070a" />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </Container>
      ) : (
        <>
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 8,
            }}
          >
            <CircularProgress color="#05070a" size={40} />
          </Container>
        </>
      )}
      <ToastContainer />
    </>
  );
};

export default GetTemplateForm;

// #002952,#dddddd,#002952,#ffffff,#252c3a,#ffffff
