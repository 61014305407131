import React from "react";
import { Container, Typography, Box, Divider } from "@mui/material";
import AppAppBar from "./AppAppBar";
import Hero from "./Hero";

const PrivacyPolicy = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AppAppBar temp="4" />
      <Hero temp="6" />
      <Container maxWidth="md" sx={{ mb: 4 }}>
        {/* Page Title */}
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Privacy Policy
        </Typography>
        <Divider sx={{ mb: 4 }} />

        {/* Last Updated */}
        {/* <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Last Updated: [Insert Date]
        </Typography> */}

        {/* Section 1: Introduction */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            1. Introduction
          </Typography>
          <Typography variant="body1" gutterBottom>
            We are committed to safeguarding your privacy. This policy explains
            how we collect, use, and disclose personal information that you
            provide when using our services and website.
          </Typography>
        </Box>

        {/* Section 2: Information We Collect */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            2. Information We Collect
          </Typography>
          <Typography variant="body1" gutterBottom>
            We may collect personal information such as your name, email
            address, and any additional information you provide when you
            communicate with us or use our services.
          </Typography>
        </Box>

        {/* Section 3: How We Use Information */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            3. How We Use Your Information
          </Typography>
          <Typography variant="body1" gutterBottom>
            We use your information to:
          </Typography>
          <Typography variant="body2" gutterBottom>
            - Provide our services and fulfill your requests.
            <br />
            - Improve our website, products, and services.
            <br />
            - Communicate with you regarding updates, promotions, or other
            relevant information.
            <br />- Comply with legal obligations.
          </Typography>
        </Box>

        {/* Section 4: Cookies */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            4. Cookies and Tracking
          </Typography>
          <Typography variant="body1" gutterBottom>
            Our website uses cookies to enhance the user experience, analyze
            usage, and optimize performance. You may disable cookies in your
            browser, but this may affect the functionality of the website.
          </Typography>
        </Box>

        {/* Section 5: Information Sharing */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            5. Information Sharing
          </Typography>
          <Typography variant="body1" gutterBottom>
            We do not sell, trade, or otherwise transfer your personal
            information to outside parties unless required for the delivery of
            our services, legal compliance, or to protect our rights.
          </Typography>
        </Box>

        {/* Section 6: Security */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            6. Security
          </Typography>
          <Typography variant="body1" gutterBottom>
            We take reasonable precautions to protect your personal information.
            However, no method of transmission over the Internet is 100% secure,
            and we cannot guarantee its absolute security.
          </Typography>
        </Box>

        {/* Section 7: Data Retention */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            7. Data Retention
          </Typography>
          <Typography variant="body1" gutterBottom>
            We retain your personal information only for as long as necessary to
            provide our services and to comply with legal obligations.
          </Typography>
        </Box>

        {/* Section 8: Your Rights */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            8. Your Rights
          </Typography>
          <Typography variant="body1" gutterBottom>
            You have the right to access, modify, or delete your personal
            information at any time. To exercise these rights, please contact us
            at [Insert Contact Info].
          </Typography>
        </Box>

        {/* Section 9: Changes to Privacy Policy */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            9. Changes to This Privacy Policy
          </Typography>
          <Typography variant="body1" gutterBottom>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page with an updated revision date.
          </Typography>
        </Box>

        {/* Section 10: Contact Us */}
        <Box mb={4}>
          <Typography variant="h6" component="h2" gutterBottom>
            10. Contact Us
          </Typography>
          <Typography variant="body1" gutterBottom>
            If you have any questions about this Privacy Policy, feel free to
            contact us at pratikshinde85499@gmail.com.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
