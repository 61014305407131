import { useContext, useEffect } from "react";
import { Context } from "../context/context";

export const useCountry = () => {
  const { setPkgPricing, setCountry, setCurrency, country, countryRows, url } =
    useContext(Context);

  const getPkgPricing = async (row = 3) => {
    try {
      const res = await fetch(
        `https://script.google.com/macros/s/AKfycbyM0d97tYIDtZk5bBK5qC54dl0qGRkjGG0jvVCyMZoRhjq2jQzzH8-YG7of58r4M9HF/exec?row=${row}`,
        {
          method: "GET",
        }
      );

      const data = await res.json();
      setPkgPricing(data);
      localStorage.setItem("fvdhvnsdygds", JSON.stringify(data));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCountry = async () => {
    if (!country) {
      const resp = await fetch(url);
      const data = await resp.json();
      setCountry(data.country_code2);
      setCurrency(data.currency.symbol);
      localStorage.setItem("jkjnde", data.country_code2);
      localStorage.setItem("kbfdk", data.currency.symbol);
      getPkgPricing(countryRows[data.country_code2]);
    } else {
      getPkgPricing(countryRows[country]);
    }
  };

  useEffect(() => {
    fetchCountry();
    // eslint-disable-next-line
  }, []);
};
